import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function HomeGallaryEdit(props) {
  console.log(props.value);

  const history = useNavigate();

  const [file, setFile] = useState(null);
  const [inputVal3, setInputval3] = useState({
    gallaryimgpath:"",
    fname3: "",
    date: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setInputval3((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData = async () => {
    const res4 = await fetch(`https://pratik-agro-backend.vercel.app/getGallaryHome/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data3 = await res4.json();

    if (res4.status === 422 || !data3) {
      console.log("error");
    } else {
      console.log(data3);
    setInputval3({
        gallaryimgpath: data3.gallaryimgpath,
      fname3: data3.fname3,
      date: data3.date,
    });
  }
  };
  useEffect(() => {
    getData();
  },[]);

  const updatData3 = async (e) => {
    e.preventDefault();

    console.log(inputVal3);

    var formData = new FormData();
    if (file != null) {
      formData.append("image3", file);
    }
    formData.append("fname3", inputVal3.fname3);
    formData.append("date", inputVal3.date);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updateHomeGallary/${id}`, formData, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/Our_Gallaryhome");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <>
      <div className="content ">
        <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/Our_Gallaryhome">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Gallary Image:</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image3"
              onChange={(e) => setFile(e.target.files[0])}
              value={inputVal3.image3}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Gallary Image Name: :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname3"
              required
              onChange={setData}
              value={inputVal3.fname3}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="date"
              required
              onChange={setData}
              value={inputVal3.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updatData3}>
              update Detail
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
export default HomeGallaryEdit;