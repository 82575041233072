import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function HomeproductEdit(props) {
  console.log(props.value);

  const history = useNavigate();

  const [file, setFile] = useState(null);
  const [inputval2, setInputval2] = useState({
    productimgpath: "",
    fname2: "",
    date: "",
  });

  const setData2 = (e) => {
    const { name, value } = e.target;
    setInputval2((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData2 = async () => {
    const res4 = await fetch(`https://pratik-agro-backend.vercel.app/getHomeproduct/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data2 = await res4.json();

    if (res4.status === 422 || !data2) {
      console.log("error");
    } else {
      console.log(data2);
    setInputval2({
      imgpath: data2.productimgpath,
      fname2: data2.fname2,
      date: data2.date,
    });
  }
  };
  useEffect(() => {
    getData2();
  },[]);

  const updateData = async (e) => {
    

    console.log(inputval2);

    var formdata2 = new FormData();
    if (file != null) {
      formdata2.append("image2", file);
    }
    formdata2.append("fname2", inputval2.fname2);
    formdata2.append("date", inputval2.date);

    for (var pair of formdata2.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updateHomeproduct/${id}`, formdata2, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/Our_producthome");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <>
      <div className="content ">
        <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/Our_producthome">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Product Image:</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image2"
              onChange={(e) => setFile(e.target.files[0])}
              value={inputval2.image2}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Product Name: :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname2"
              onChange={setData2}
              value={inputval2.fname2}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="date"
              onChange={setData2}
              value={inputval2.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updateData}>
              update Detail
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
export default HomeproductEdit;