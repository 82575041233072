import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import BellNotifiaction from '../BellNotifiaction'
import PostsOfFruitandVeg from '../PostofFruitandVeg';

export default function FruitAndVegProduct() {
    const [fname9, setfname9] = useState("");
    const [FruitandVegProductDescription, setFruitandVegProductDescription] = useState("");
  const [newFile9, setNewFile9] = useState(null);
  const [newdata9, setnewData9] = useState([]);



  const setData9 = (e) => {
    const { value } = e.target;
    setfname9(value);
  };
  const setData10 = (e) => {
    const { value } = e.target;
    setFruitandVegProductDescription(value);
  };
  const setimgfile = (e) => {
    setNewFile9(e.target.files[0]);
  };

  // Add image

  const addUserdata9 = async (e) => {
    
    var formData9 = new FormData();
    console.log(newFile9);
    var filename = newFile9.name;
    formData9.append("photo9", newFile9, filename);
    formData9.append("fname9", fname9);
    formData9.append("FruitandVegProductDescription", FruitandVegProductDescription);
    console.log(formData9);

    const config = {
      headers: {
        "Content-Type": " multipart/form-data",
      },
    };

    const res = await axios.post(
      "https://pratik-agro-backend.vercel.app/AddFruitAndVEgProductimg",
      formData9,
      config
    );

    console.log(res);
  };
  //get image

  const detUserData9 = async () => {
    const res9 = await axios.get("https://pratik-agro-backend.vercel.app/getFruitAndVegproductimg", {
      headers: {
        "Content-Type": " application/json",
      },
    });
    if (res9.data.status === 401 || !res9.data) {
      console.log("error");
    } else {
      setnewData9(res9.data.getImage.reverse());
    }
  };
  useEffect(() => {
    detUserData9();
  }, []);
 
//delete data
const deleteimage = async (id) => {
 
  const res6 = await fetch(`https://pratik-agro-backend.vercel.app/deletedata9/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const deleteStud = await res6.json();
  console.log(deleteStud);
  if (res6.status === 422 || !deleteStud) {
    console.log("error");
  } else {
    detUserData9();
  }

};
  return (
    <div className='content'>
          <div className="mt-2 mb-1 d-flex justify-content-between align-items-center">
        <i>
          <h2>Products </h2>
        </i>
       <div>
     
       <BellNotifiaction />
       <Link
          type="button"
          to="/Addhomeimage"
          className="btn btn-light shadow addimage "
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Add Image
        </Link>
       </div>
        
        
      </div>
      <div className=" container text-end mt-5">
        {/* <Link  to="/Addhomeimage"className="btn btn-success "> Add Data</Link> */}
        
        <div
          class="modal fade "
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                  Fruit And Veg Product
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form>
                  <div>
                    
                      <p style={{float:'left'}}>Product Name:-</p>
                    
                    <input
                      type="text"
                      name="fname9"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setData9}
                    />

                     <p style={{float:'left'}}>Product Description:-</p>
                    
                    <input
                      type="text"
                      name="FruitandVegProductDescription"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setData10}
                    />

                    <div>
                      <p style={{float:'left'}}>Add Your Image:-</p>
                    </div>
                    <input
                      type="file"
                      name="photo9"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setimgfile}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary mt-5"
                       onClick={addUserdata9}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
         
        
           <PostsOfFruitandVeg  posts={newdata9} deleteimage={deleteimage}/>
        
     
    </div>
  )
}
