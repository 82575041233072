import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function GallaryBgEdit() {
    const history = useNavigate();

  const [file14, setFile14] = useState(null);
  const [inputval14, setInputVal14] = useState({
    GallaryBgimg:"",
    fname14: "",
    date: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setInputVal14((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData = async () => {
    const res14 = await fetch(`https://pratik-agro-backend.vercel.app/getGallaryBgimg/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data3 = await res14.json();

    if (res14.status === 422 || !data3) {
      console.log("error");
    } else {
      console.log(data3);
    setInputVal14({
        GallaryBgimg: data3.GallaryBgimg,
      fname14: data3.fname14,
      date: data3.date,
    });
  }
  };
  useEffect(() => {
    getData();
  }, []);

  const updateData8 = async (e) => {
    e.preventDefault();

    console.log(inputval14);

    var formData14 = new FormData();
    if (file14 != null) {
      formData14.append("image14", file14);
    }
    formData14.append("fname14", inputval14.fname14);
    formData14.append("date", inputval14.date);

    for (var pair of formData14.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updateGallaryBgimg/${id}`, formData14, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/GallaryBg");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <div className='content'>
         <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/GallaryBg">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">BackgroundImage:</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image14"
              onChange={(e) => setFile14(e.target.files[0])}
              value={inputval14.image14}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">BackgroundImage Name: :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname14"
              onChange={setData}
              value={inputval14.fname14}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="date"
              onChange={setData}
              value={inputval14.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updateData8}>
              update Detail
            </Link>
          </div>
        </form>
    </div>
  )
}