import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function InquiryBackgroundEdit() {
    const history = useNavigate();

  const [file20, setFile20] = useState(null);
  const [inputval20, setinputVal20] = useState({
    inquryBackground:"",
    fname20:"",
    date:"",
  });
    console.log(inputval20)
  const setData = (e) => {
    const { name, value } = e.target;
    setinputVal20((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData = async () => {
    const res7 = await fetch(`https://pratik-agro-backend.vercel.app/getinquryBackground/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data3 = await res7.json();

    if (res7.status === 422 || !data3) {
      console.log("error");
    } else {
      console.log(data3);
    setinputVal20({
        inquryBackground: data3.inquryBackground,
       
      fname20: data3.fname20,
      date: data3.date,
    });
  }
  };
  useEffect(() => {
    getData();
  }, []);

  const updatedata5 = async (e) => {
    e.preventDefault();

    console.log(inputval20);

    var formData5 = new FormData();
    if (file20 != null) {
      formData5.append("image20", file20);
    }
    formData5.append("fname20", inputval20.fname20);
    formData5.append("date", inputval20.date);
   
    for (var pair of formData5.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updateinquryBg/${id}`, formData5, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/inquryBackground");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <div className='content'>
         <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/inquryBackground">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Background Image:</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image20"
              onChange={(e) => setFile20(e.target.files[0])}
              value={inputval20.image20}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">background Image  Name: :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname20"
              onChange={setData}
              value={inputval20.fname20}
            />
          </div>
          
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="date"
              onChange={setData}
              value={inputval20.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updatedata5}>
              update Detail
            </Link>
          </div>
        </form>
    </div>
  )
}