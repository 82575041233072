import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

// const UserContext = createContext();
function AdminLogin() {
  const history = useNavigate();
  // <UserContext.Provider value={text}>
     
  //     <Dashboard />
  //   </UserContext.Provider>
  // const { loggedIn, setLoggedIn } = useContext(UserContext);
 const text='password is correct'
  const [inputVal, setinputVal] = useState({
    username: "",
    password: "",
  });

  // console.log(loggedIn);
  const setData = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setinputVal((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };

  //onclick function
  const assinpudata = async (e) => {
    e.preventDefault();
    const { username, password } = inputVal;
    const res = await fetch("https://pratik-agro-backend.vercel.app/login", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });
    const data = await res.json();
    console.log(data);

    if (res.status < 300 && res.status >= 200) {
      setinputVal(data);

      alert("Login successfully");
     
      secureLocalStorage.setItem("text",text)
      

      history("/Home");
    } else {
      alert("please enter correct password!");
      history("/");
    }
  };

  return (
    <>
      <div className="hero">
        <div className="form-box">
          <h4 className="text-center mt-5"> PRATIK AGRO EXPORTS</h4>
          <div className=" text-center mt-3">
            <img
              src="images/logo.png"
              alt="logo"
              className="navbar-brand img-fluid "
              style={{ width: "4rem", height: "2rem" }}
            />
          </div>

          {/* <div className="button-box">
            <div id="btn"></div>
            <button type="button" className="toggle-btn">
              Log in
            </button>
          </div> */}

          <form id="login" className="input-group">
            <input
              type="text"
              className="input-field"
              placeholder="User-Name"
              
              name="username"
              onChange={setData}
              value={inputVal.username}
              
            />
            <input
              type="text"
              className="input-field"
              placeholder="Enter-password"
              
              name="password"
              onChange={setData}
              value={inputVal.password}
            />
            <button
              type="submit"
              className="submit-btn text-center"
              onClick={assinpudata}
            >
              Log in
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default AdminLogin;
