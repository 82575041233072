import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function CerealProductEdit() {
    const history = useNavigate();

  const [file7, setFile7] = useState(null);
  const [inputVal7, setinputVal7] = useState({
    cerealsProductimg:"",
    cerealDescription:"",
    fname7: "",
    date: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setinputVal7((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData = async () => {
    const res7 = await fetch(`https://pratik-agro-backend.vercel.app/getCerealProductImg/${id}`,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data3 = await res7.json();

    if (res7.status === 422 || !data3) {
      console.log("error");
    } else {
      console.log(data3);
    setinputVal7({
        cerealsProductimg: data3.cerealsProductimg,
        cerealDescription:data3.cerealDescription,
      fname7: data3.fname7,
      date: data3.date,
    });
  }
  };
  useEffect(() => {
    getData();
  }, []);

  const updatedata5 = async (e) => {
    e.preventDefault();

    console.log(inputVal7);

    var formData5 = new FormData();
    if (file7 != null) {
      formData5.append("image7", file7);
    }
    formData5.append("fname7", inputVal7.fname7);
    formData5.append("date", inputVal7.date);
    formData5.append("cerealDescription", inputVal7.cerealDescription);
    for (var pair of formData5.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updateCerealProductimg/${id}`, formData5, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/CerealsProductImage");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <div className='content'>
         <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/CerealsProductImage">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Products Image:</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image7"
              onChange={(e) => setFile7(e.target.files[0])}
              value={inputVal7.image5}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Products Name: :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname7"
              onChange={setData}
              value={inputVal7.fname7}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Products Description :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="cerealDescription"
              onChange={setData}
              value={inputVal7.cerealDescription}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="date"
              onChange={setData}
              value={inputVal7.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updatedata5}>
              update Detail
            </Link>
          </div>
        </form>
    </div>
  )
}