import { useState } from "react";

import Pagination from "./Pagination";

const PostsOfInquaryProducts = ({ posts, deleteimage }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [postPerPage] = useState(6);
  console.log(posts);
  if (posts.length <= 0) {
    return <h2>Loading...</h2>;
  }

  const currentPost = posts.slice(
    postPerPage * currentPage,
    postPerPage * (currentPage + 1)
  );
  return (
    <>
    <div class="outer-wrapper">
    <div class="table-wrapper"> 
    <table class="table  mt-5">
      <thead className="bg-dark text-white">
          <tr>
            <th scope="col" className='text-center'>ID</th>
            <th scope="col" className='text-center'> Name</th>
            <th scope="col" className='text-center'> Company Name</th>
            <th scope="col" className='text-center'>Uploaded Date</th>
            <th scope="col" className='text-center'>Email Address</th>
            <th scope="col" className='text-center'>Contact Number </th>
            <th scope="col" className='text-center'>Country</th>
            <th scope="col" className='text-center'>State</th>
            <th scope="col" className='text-center'>Product Name</th>
            <th scope="col" className='text-center'>Description</th>
            <th scope="col" className='text-center'>Action</th>
          </tr>
        </thead>
      {currentPost.map((post, id) => {
        console.log(post);
        return (
          
          <tbody>
            <tr>
              <th scope="row ">{id + 1}</th>
              <td className="text-center">{post.Name}</td>
              <td className="text-center">{post.Company_Name}</td>
              <td className="text-center">{post.date}</td>

              <td className="text-center">{post.Email_Address}</td>
              <td className="text-center">{post.Contact_Number}</td>
              <td className="text-center">{post.Country}</td>
              <td className="text-center">{post.State}</td>
              <td className="text-center">{post.Product_Name}</td>
              <td className="text-center">{post.Description}</td>

              <td>
                <button type="button" class="btn btn-outline-dark ">
                  <i
                    class="bi bi-archive-fill "
                    onClick={() => deleteimage(post._id)}
                  ></i>
                </button>
              </td>
            </tr>
          </tbody>
          
        );
      })}
      </table>
      </div>
      </div>
      <Pagination
        postPerPage={postPerPage}
        totalPost={posts.length}
        paginate={(e) => setCurrentPage(e)}
      />
    </>
  );
};
export default PostsOfInquaryProducts;
