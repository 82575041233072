import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import BellNotifiaction from '../BellNotifiaction';
import PostsOfCerealsProducts from '../PostofcerealsProduct';

export default function CerealsProductImage() {
  const [fname7, setfname7] = useState("");
  const [cerealDescription, setcerealDescription] = useState("");
  const [newFile7, setNewFile7] = useState(null);
  const [newdata7, setnewdata7] = useState([]);



  const setData7 = (e) => {
    const { value } = e.target;
    setfname7(value);
   
  };
  const setData9 = (e) => {
    const { value } = e.target;
    
    setcerealDescription(value)
  };
  const setimgfile = (e) => {
    setNewFile7(e.target.files[0]);
  };

  // Add image

  const adduserdata7 = async (e) => {
    
    var formData7 = new FormData();
    console.log(newFile7);
    var filename = newFile7.name;
    formData7.append("photo7", newFile7, filename);
    formData7.append("fname7", fname7);
    formData7.append("cerealDescription", cerealDescription);
    console.log(formData7);

    const config = {
      headers: {
        "Content-Type": " multipart/form-data",
      },
    };

    const res = await axios.post(
      "https://pratik-agro-backend.vercel.app/AddCerealsproductimg",
      formData7,
      config
    );

    console.log(res);
  };
  //get image

  const getUserData7 = async () => {
    const res7 = await axios.get("https://pratik-agro-backend.vercel.app/getCerealsProductImg", {
      headers: {
        "Content-Type": " application/json",
      },
    });
    if (res7.data.status === 401 || !res7.data) {
      console.log("error");
    } else {
      setnewdata7(res7.data.getImage.reverse());
    }
  };
  useEffect(() => {
    getUserData7();
  }, []);
 
//delete data
const deleteimage = async (id) => {
 
  const res6 = await fetch(`https://pratik-agro-backend.vercel.app/deletedata7/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const deleteStud = await res6.json();
  console.log(deleteStud);
  if (res6.status === 422 || !deleteStud) {
    console.log("error");
  } else {
    getUserData7();
  }

};
  return (
    <div className='content'>
   <div className="mt-2 mb-1 d-flex justify-content-between align-items-center">
        <i>
          <h2>Products</h2>
        </i>
       <div>
     
       <BellNotifiaction />
       <Link
          type="button"
          to="/Addhomeimage"
          className="btn btn-light shadow addimage "
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Add Image
        </Link>
       </div>
        
        
      </div>
      <div className=" container text-end mt-5">
        {/* <Link  to="/Addhomeimage"className="btn btn-success "> Add Data</Link> */}
        
        <div
          class="modal fade "
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                  Cereals Product 
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form>
                  <div>
                    
                      <p style={{float:'left'}}>Product Name:-</p>
                    
                    <input
                      type="text"
                      name="fname7"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setData7}
                    />

                   <p style={{float:'left'}}>Product Description:-</p>
                    <input
                      type="text"
                      name="cerealDescription"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setData9}
                    />

                    <div>
                      <p style={{float:'left'}}>Add Your Image:-</p>
                    </div>
                    <input
                      type="file"
                      name="photo7"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setimgfile}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary mt-5"
                       onClick={adduserdata7}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
       
       
        
          <PostsOfCerealsProducts posts={newdata7} deleteimage={deleteimage}/>
        
     
    </div>
  )
}
