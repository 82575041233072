
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";

const ProtectedRoute = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      if (secureLocalStorage.getItem("text")) {
        setCurrentUser({ username: "sumitpathak", password: "123454d" });
      } else {
        setCurrentUser(null);
      }
    }, 50);
  }, []);

  if (currentUser === undefined) {
    return null;
  }

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;