import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import BellNotifiaction from '../BellNotifiaction'
import PostsOfGalleryProducts from '../PostOfGallaryProducts';

export default function GallaryProduct() {
    const [fname15, setfname15] = useState("");
    const [newFile15, setNewFile15] = useState(null);
    const [newdata15, setnewData15] = useState([]);
    
  
  
    const setData15 = (e) => {
      const { value } = e.target;
      setfname15(value);
    };
    const setimgfile = (e) => {
      setNewFile15(e.target.files[0]);
    };
  
    // Add image
  
    const addUserData15 = async (e) => {
      
      var formData15 = new FormData();
      console.log(newFile15);
      var filename = newFile15.name;
      formData15.append("photo15", newFile15, filename);
      formData15.append("fname15", fname15);
      console.log(formData15);
  
      const config = {
        headers: {
          "Content-Type": " multipart/form-data",
        },
      };
  
      const res = await axios.post(
        "https://pratik-agro-backend.vercel.app/AddGallaryProductimg",
        formData15,
        config
      );
  
      console.log(res);
    };
    //get image
  
    const getUserData15 = async () => {
      const res14 = await axios.get("https://pratik-agro-backend.vercel.app/getGallaryProductimg", {
        headers: {
          "Content-Type": " application/json",
        },
      });
      if (res14.data.status === 401 || !res14.data) {
        console.log("error");
      } else {
        setnewData15(res14.data.getImage.reverse());
      }
    };
    useEffect(() => {
      getUserData15();
    }, []);
   
  //delete data
  const deleteimage = async (id) => {
   
    const res15 = await fetch(`https://pratik-agro-backend.vercel.app/deletedata15/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const deleteStud = await res15.json();
    console.log(deleteStud);
    if (res15.status === 422 || !deleteStud) {
      console.log("error");
    } else {
      getUserData15();
    }
  
  };
  return (
    <div className='content'>
       <div className="mt-2 mb-1 d-flex justify-content-between align-items-center">
        <i>
          <h2>Product Details-</h2>
        </i>
       <div>
     
       <BellNotifiaction />
       <Link
          type="button"
          to="/Addhomeimage"
          className="btn btn-light shadow addimage "
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Add Image
        </Link>
       </div>
        
        
      </div>
      <div className=" container text-end mt-5">
        {/* <Link  to="/Addhomeimage"className="btn btn-success "> Add Data</Link> */}
        
        <div
          class="modal fade "
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                   Gallary ProductImage
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form>
                  <div>
                    
                      <p style={{float:'left'}}>ProductImage Name:-</p>
                    
                    <input
                      type="text"
                      name="fname15"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setData15}
                    />

                    <div>
                      <p style={{float:'left'}}>Add Your Image:-</p>
                    </div>
                    <input
                      type="file"
                      name="photo15"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setimgfile}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary mt-5"
                       onClick={addUserData15}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
          
        
       
        
          
           
              
               <PostsOfGalleryProducts  posts={newdata15} deleteimage={deleteimage}/>
                
            
          
        
        
    
      
    </div>
  )
}
