import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function GetintouchEdit(props) {
  console.log(props.value);

  const history = useNavigate();

  const [file19, setFile19] = useState(null);
  const [inputval19, setIntputvalue19] = useState({
    getinTouchbgimgpath:"",
    fname18: "",
    date: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setIntputvalue19((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData = async () => {
    const res3 = await fetch(`https://pratik-agro-backend.vercel.app/getgetinTouchbgimgpath/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data1 = await res3.json();

    if (res3.status === 422 || !data1) {
      console.log("error");
    } else {
      console.log(data1);
    setIntputvalue19({
        getinTouchbgimgpath: data1.getinTouchbgimgpath,
      fname18: data1.fname18,
      date: data1.date,
    });
  }
  };
  useEffect(() => {
    getData();
  },[]);

  const updateData = async (e) => {
    e.preventDefault();

    console.log(inputval19);

    var formData19 = new FormData();
    if (file19 != null) {
      formData19.append("image18", file19);
    }
    formData19.append("fname18", inputval19.fname18);
    formData19.append("date", inputval19.date);

    for (var pair of formData19.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updategetinTouchbgimgpath/${id}`, formData19, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/Get_in_touch");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <>
      <div className="content ">
        <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/Get_in_touch">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
         
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Background img:</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image18"
              onChange={(e) => setFile19(e.target.files[0])}
              value={inputval19.image18}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Background image Name :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname18"
              required
              onChange={setData}
              value={inputval19.fname18}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="date"
              required
              onChange={setData}
              value={inputval19.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updateData}>
              update Detail
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
export default  GetintouchEdit;