import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AdminLogin from "./component/AdminLogin";
import Dashboard from "./component/Dashboard";

import About from "./component/About Page/About";
import Edit from "./component/HomeCarsouleEdit";
// import Gallary from "./component/Gallary";

import Home from "./component/Home";

import Inqury from "./component/Inqury";
// import Product from "./component/Product";
import AboutBgImg from "./component/About Page/AboutEdit";
import Team from "./component/About Page/Team";
import TeamEdit from "./component/About Page/TeamEdit";
import GallaryBg from "./component/Gallary Page/GallaryBg";
import GallaryBgEdit from "./component/Gallary Page/GallaryBgEdit";
import GallaryProduct from "./component/Gallary Page/GallaryProduct";
import GallaryProductEdit from "./component/Gallary Page/GallaryProductEdit";
import GetintouchEdit from "./component/GetintouchEdit";
import Get_in_touch from "./component/Get_in_touch";
import Our_Gallaryhome from "./component/Home page/Our_Gallaryhome";
import Our_producthome from "./component/Home page/Our_producthome";
import HomeGallaryEdit from "./component/HomeGallaryEdit";
import HomeproductEdit from "./component/Homeproductedit";
import InquryBAckground from "./component/InquryBAckground";
import InquiryBackgroundEdit from "./component/InquryBackgroundEdit";
import Cereals from "./component/product Page/CerealsBackground";
import CerealsbgEdit from "./component/product Page/CerealsBgEdit";
import CerealProductEdit from "./component/product Page/CerealsproductEdit";
import CerealsProductImage from "./component/product Page/CerealsProductImage";
import FruitandvegBg from "./component/product Page/FruitandvegBg";
import FruitandVegEdit from "./component/product Page/FruitandVegEdit";
import FruitAndVegProduct from "./component/product Page/FruitAndVegProduct";
import FruitandVegProductEdit from "./component/product Page/FruitAndVegProductsEdit";
import ProcessedfoodBg from "./component/product Page/ProcessedfoodBg";
import ProcessedFoodbgEdit from "./component/product Page/ProcessedfoodbgEdit";
import ProcessedFoodProduct from "./component/product Page/ProcessedFoodProduct";
import ProcessedFoodProductEdit from "./component/product Page/ProcessedfoodproductEdit";
import SpiceBg from "./component/product Page/SpiceBg";
import SpiceBgEdit from "./component/product Page/SpiceBgEdit";
import SpiceProduct from "./component/product Page/SpiceProduct";
import SpiceProductEdit from "./component/product Page/SpiceProductEdit";
import ProtectedRoute from "./component/ProtectedRoute";
import WininternationalTrade from "./component/Win_international_Trade";
import WininternationalbgEdit from "./component/Win_international_TradebgEdit";

// export const UserContext = createContext();
function App() {
  // const [LoggedIn, setLoggedIn] = useState(true);

  return (
    <>
      {/* <UserContext.Provider value={{ LoggedIn, setLoggedIn }}> */}
      <BrowserRouter>
        <Dashboard />
        <Routes>
          <Route
            path="*"
            element={
              <h1 className="content text-center ">404 Page is not Found</h1>
            }
          />
          <Route path="/" element={<AdminLogin />} />

          <Route
            path="/Home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route path="/About Background Image" element={<ProtectedRoute>
            <About />
          </ProtectedRoute>} />
          {/* <Route path="/Product" element={<ProtectedRoute>
                <Product/>
              </ProtectedRoute>} /> */}
          {/* <Route path="/Gallary" element={<ProtectedRoute>
                <Gallary />
              </ProtectedRoute>} /> */}
          <Route path="/Inqury" element={<ProtectedRoute>
            <Inqury />
          </ProtectedRoute>} />
          <Route
            path="/Win_international_Trade"
            element={<ProtectedRoute>
              <WininternationalTrade />
            </ProtectedRoute>}
          />
          <Route path="/Get_in_touch" element={<ProtectedRoute>
            <Get_in_touch />
          </ProtectedRoute>} />

          <Route path="/Edit/:id" element={<Edit />} />
          <Route
            path="/Our_producthome"
            element={<ProtectedRoute>
              <Our_producthome />
            </ProtectedRoute>}
          />
          <Route
            path="/Our_Gallaryhome"
            element={<ProtectedRoute>
              <Our_Gallaryhome />
            </ProtectedRoute>}
          />
          <Route
            path="/HomeproductEdit/:id"
            element={<ProtectedRoute>
              < HomeproductEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/HomeGallaryEdit/:id"
            element={<ProtectedRoute>
              < HomeGallaryEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/AboutBgEdit/:id"
            element={<ProtectedRoute>
              < AboutBgImg />
            </ProtectedRoute>}
          />
          <Route
            path="/Team_member_Edit/:id"
            element={<ProtectedRoute>
              < TeamEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/CerealsbgEdit/:id"
            element={<ProtectedRoute>
              < CerealsbgEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/CerealsproductEdit/:id"
            element={<ProtectedRoute>
              < CerealProductEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/FruitAndVegEdit/:id"
            element={<ProtectedRoute>
              < FruitandVegEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/FruitAndVegProductEdit/:id"
            element={<ProtectedRoute>
              < FruitandVegProductEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/ProcessedFoodbgEdit/:id"
            element={<ProtectedRoute>
              < ProcessedFoodbgEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/ProcessedFoodProductEdit/:id"
            element={<ProtectedRoute>
              < ProcessedFoodProductEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/SpiceBgEdit/:id"
            element={<ProtectedRoute>
              < SpiceBgEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/SpiceProductEdit/:id"
            element={<ProtectedRoute>
              < SpiceProductEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/GallaryBgEdit/:id"
            element={<ProtectedRoute>
              < GallaryBgEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/GallaryProductEdit/:id"
            element={<ProtectedRoute>
              < GallaryProductEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/GetintouchEdit/:id"
            element={<ProtectedRoute>
              < GetintouchEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/InquiryBackgroundEdit/:id"
            element={<ProtectedRoute>
              < InquiryBackgroundEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/WininternationalbgEdit/:id"
            element={<ProtectedRoute>
              < WininternationalbgEdit />
            </ProtectedRoute>}
          />
          <Route
            path="/Team_members"
            element={<ProtectedRoute>
              < Team />
            </ProtectedRoute>}
          />
          <Route
            path="/Cereals"
            element={<ProtectedRoute>
              < Cereals />
            </ProtectedRoute>}
          />
          <Route
            path="/CerealsProductImage"
            element={<ProtectedRoute>
              < CerealsProductImage />
            </ProtectedRoute>}
          />
          <Route
            path="/Fruitandvegbg"
            element={<ProtectedRoute>
              < FruitandvegBg />
            </ProtectedRoute>}
          />
          <Route
            path="/FruitandveProduct"
            element={<ProtectedRoute>
              < FruitAndVegProduct />
            </ProtectedRoute>}
          />
          <Route
            path="/ProcessedfoodBg"
            element={<ProtectedRoute>
              < ProcessedfoodBg />
            </ProtectedRoute>}
          />
          <Route
            path="/ProcessedfoodProduct"
            element={<ProtectedRoute>
              < ProcessedFoodProduct />
            </ProtectedRoute>}
          />
          <Route
            path="/SpiceBg"
            element={<ProtectedRoute>
              < SpiceBg />
            </ProtectedRoute>}
          />
          <Route
            path="/SpiceProduct"
            element={<ProtectedRoute>
              < SpiceProduct />
            </ProtectedRoute>}
          />
          <Route
            path="/GallaryBg"
            element={<ProtectedRoute>
              < GallaryBg />
            </ProtectedRoute>}
          />
          <Route
            path="/GallaryProduct"
            element={<ProtectedRoute>
              < GallaryProduct />
            </ProtectedRoute>}
          />
          <Route
            path="/inquryBackground"
            element={<ProtectedRoute>
              < InquryBAckground />
            </ProtectedRoute>}
          />

        </Routes>
      </BrowserRouter>
      {/* </UserContext.Provider> */}
    </>
  );
}

export default App;
