import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function AboutBgImg(props) {
  console.log(props.value);

  const history = useNavigate();

  const [file4, setFile4] = useState(null);
  const [inputVal4, setinputVal4] = useState({
    Backgroundimg:"",
    fname4: "",
    date: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setinputVal4((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData4 = async () => {
    const res5 = await fetch(`https://pratik-agro-backend.vercel.app/getAboutbackgroundImg/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data3 = await res5.json();

    if (res5.status === 422 || !data3) {
      console.log("error");
    } else {
      console.log(data3);
    setinputVal4({
        Backgroundimg: data3.Backgroundimg,
      fname4: data3.fname4,
      date: data3.date,
    });
  }
  };
  useEffect(() => {
    getData4();
  },[]);

  const updatedata4 = async (e) => {
    e.preventDefault();

    console.log(inputVal4);

    var formData = new FormData();
    if (file4 != null) {
      formData.append("image4", file4);
    }
    formData.append("fname4", inputVal4.fname4);
    formData.append("date", inputVal4.date);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updateAboutbgimg/${id}`, formData, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/About Background Image");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <>
      <div className="content ">
        <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/About Background Image">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label"> Background Image:</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image4"
              onChange={(e) => setFile4(e.target.files[0])}
              value={inputVal4.image4}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label"> Background Image Name: :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname4"
              onChange={setData}
              value={inputVal4.fname4}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="Description"
              onChange={setData}
              value={inputVal4.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updatedata4}>
              update Detail
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
export default AboutBgImg;