import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import BellNotifiaction from '../BellNotifiaction';
import Modal from "react-modal";

Modal.setAppElement("#root");
export default function Our_Gallaryhome() {
  const [fname3, setfname3] = useState("");
  const [newFile3, setNewFile3] = useState(null);
  const [newdata3, setnewdata3] = useState([]);

// const {setLoggedIn,LoggedIn} = useContext(UserContext);

// console.log(LoggedIn);
const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedImage, setSelectedImage] = useState("");

const handleImageClick = (imageSrc) => {
  setSelectedImage(imageSrc);
  setIsModalOpen(true);
};

const handleCloseModal = () => {
  setIsModalOpen(false);
};



  const setData3 = (e) => {
    const { value } = e.target;
    setfname3(value);
  };
  const setimgfile = (e) => {
    setNewFile3(e.target.files[0]);
  };

  // Add image

  const adduserData3 = async (e) => {
    
    var formdata3 = new FormData();
    console.log(newFile3);
    var filename = newFile3.name;
    formdata3.append("photo3", newFile3, filename);
    formdata3.append("fname3", fname3);
    console.log(formdata3);

    const config = {
      headers: {
        "Content-Type": " multipart/form-data",
      },
    };

    const res = await axios.post(
      "https://pratik-agro-backend.vercel.app/AddHomeGallary",
      formdata3,
      config
    );

    console.log(res);
  };
  //get image

  const getUserData3 = async () => {
    const res3 = await axios.get("https://pratik-agro-backend.vercel.app/getHomegallaryImg", {
      headers: {
        "Content-Type": " application/json",
      },
    });
    if (res3.data.status === 401 || !res3.data) {
      console.log("error");
    } else {
      setnewdata3(res3.data.getImage.reverse());
    }
  };
  useEffect(() => {
    getUserData3();
  }, []);
 
//delete data
const deleteimage3 = async (id) => {
 
  const res4 = await fetch(`https://pratik-agro-backend.vercel.app/deletedata3/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const deleteStud = await res4.json();
  console.log(deleteStud);
  if (res4.status === 422 || !deleteStud) {
    console.log("error");
  } else {
    getUserData3();
  }

};
  return (
    <div  className="content">
     
     <div className="mt-2 mb-1 d-flex justify-content-between align-items-center">
        <i>
          <h2>Our Gallary</h2>
        </i>
       <div>
     
       <BellNotifiaction />
       <Link
          type="button"
          to="/Addhomeimage"
          className="btn btn-light shadow addimage "
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Add Image
        </Link>
       </div>
        
        
      </div>
     <div className=" container text-end mt-5">
        {/* <Link  to="/Addhomeimage"className="btn btn-success "> Add Data</Link> */}
        
        <div
          class="modal fade "
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                  HomeGallary Image
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form>
                  <div>
                    
                      <p style={{float:'left'}}>Gallary Image Name:-</p>
                    
                    <input
                      type="text"
                      name="fname3"
                      required
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setData3}
                    />

                    <div>
                      <p style={{float:'left'}}>Add Your Image:-</p>
                    </div>
                    <input
                      type="file"
                      name="photo3"
                      required
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setimgfile}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary mt-5"
                       onClick={adduserData3}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="outer-wrapper">
    <div class="table-wrapper">
       <table class="table  mt-5">
        <thead className="bg-dark text-white ">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Gallary Image</th>
            <th scope="col">Gallary Name</th>
            <th scope="col">Uploaded Date</th>
            <th scope="col">Action</th>
            
          </tr>
          </thead>
          <tbody>
          {newdata3.length>0 ?newdata3.map((el,id) => {
            console.log(el)
            return (
              <>
                <tr>
                  <th scope="row mt-5">{id+1}</th>
                  <td><img src={el.gallaryimgpath} alt='img'  style={{'width':'70px',textAlign:"center",margin:'auto', cursor:"pointer"}}onClick={() => handleImageClick(el.gallaryimgpath)} className='img-fluid'/></td>
                  <td>{el.fname3}</td>
                  <td>{el.date}</td>
                  {/* <td>
                    <NavLink to={`/Edit/${el._id}`}><i className="bi bi-pencil-square p-4 shadow"  ></i></NavLink>
                    <i class="bi bi-archive-fill p-4 shadow" onClick={()=>deleteimage(el._id)}></i>
                  </td> */}
                  <td>
                        <button type="button" class="btn btn-outline-dark "><NavLink to={`/HomeGallaryEdit/${el._id}`}><i className="bi bi-pencil-square "  ></i></NavLink></button>
                        <button type="button" class="btn btn-outline-dark "><i class="bi bi-archive-fill " onClick={()=>deleteimage3(el._id)}></i></button>
                  </td>
                </tr>
              </>
            );
          }):"Loading.."
          } 
        </tbody>
        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} style={{ content: { width: '50%', height: '50%', margin: 'auto', backgroundColor: 'white' } }}>
     <button onClick={handleCloseModal} className="btn btn-danger"><i class="bi bi-x-lg"></i></button>
  <div className='text-center mt-5'>
    <img src={selectedImage} alt="img" style={{width: '30%', height: '35%%'}} className='text-center' />
  </div>
  
</Modal>
      </table>
      </div>
      </div>
    </div>
  )
}