import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function FruitandVegProductEdit() {
    const history = useNavigate();

  const [file9, setFile9] = useState(null);
  const [inputVal9, setinputVal9] = useState({
    FruitAndVegproductimg:"",
    fname9: "",
    date: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setinputVal9((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData = async () => {
    const res9 = await fetch(`https://pratik-agro-backend.vercel.app/getFruitAndVegproductimg/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data3 = await res9.json();

    if (res9.status === 422 || !data3) {
      console.log("error");
    } else {
      console.log(data3);
    setinputVal9({
        FruitAndVegproductimg: data3.FruitAndVegproductimg,
      fname9: data3.fname9,
      FruitandVegProductDescription:data3.FruitandVegProductDescription,
      date: data3.date,
    });
  }
  };
  useEffect(() => {
    getData();
  }, []);

  const updateData9 = async (e) => {
    e.preventDefault();

    console.log(inputVal9);

    var formData9 = new FormData();
    if (file9 != null) {
      formData9.append("image9", file9);
    }
    formData9.append("fname9", inputVal9.fname9);
    formData9.append("date", inputVal9.date);
    formData9.append("FruitandVegProductDescription", inputVal9.FruitandVegProductDescription);

    for (var pair of formData9.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updateFruitAndVegproductimg/${id}`, formData9, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/FruitandveProduct");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <div className='content'>
         <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/FruitandveProduct">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Products Image:</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image9"
              onChange={(e) => setFile9(e.target.files[0])}
              value={inputVal9.image9}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Products Name: :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname9"
              onChange={setData}
              value={inputVal9.fname9}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Products Description :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="FruitandVegProductDescription"
              onChange={setData}
              value={inputVal9.FruitandVegProductDescription}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="date"
              onChange={setData}
              value={inputVal9.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updateData9}>
              update Detail
            </Link>
          </div>
        </form>
    </div>
  )
}