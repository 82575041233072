import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import BellNotifiaction from './BellNotifiaction'
import PostsOfInquaryProducts from './PostofInqury';


export default function Inqury() {

    const [getstud,setgetstud]=useState([]);
    console.log(getstud)

    const getData= async()=>{
        const res =await fetch("https://pratik-agro-backend.vercel.app/getinqurypageDetails",{
           method: "GET",
           headers: {
            "Content-Type":"application/json"
           }
        });
        const data =await res.json();
        console.log(data);
        if(res.status === 422 || !data){
           console.log(data)
        } else{
           setgetstud(data.getDetails.reverse())
           console.log("get data");
        }
    }
    useEffect(()=>{
         getData();
    },[])

  //delete data
const deleteimage = async (id) => {
 
  const res3 = await fetch(`https://pratik-agro-backend.vercel.app/deletedata16/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const deleteStud = await res3.json();
  console.log(deleteStud);
  if (res3.status === 422 || !deleteStud) {
    console.log("error");
  } else {
    getData();
  }

};
  return (
    <div className='content'>
    <div className="mt-2 mb-1 d-flex justify-content-between align-items-center">
        <i>
          <h2>Inquiry page</h2>
        </i>
       <div>
     
       <BellNotifiaction/>
       
       </div>
        
        
      </div>
           
        
        
        
           <PostsOfInquaryProducts posts={getstud} deleteimage={deleteimage}/>
        
     
    </div>
  )
  }
