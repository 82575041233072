import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
function Dashboard() {
  // const text = useContext(UserContext);
  // console.log(text)
  const history = useNavigate();
  const text = localStorage.getItem("@secure.s.text");
  const clearstorage = () => {
    localStorage.clear(text);
    history("/");
    window.location.reload();
  };
  return (
    <>
      <div className="sidebar">
        <div className=" text-center">
          <img
            src="images/logo.png"
            alt="logo"
            className="navbar-brand img-fluid mt-2 "
            style={{ width: "7rem", height: "3rem" }}
          />
        </div>
        <hr></hr>
        <NavLink  className="nav-item dropdown">
          <NavLink
            className="nav-link dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-house-door-fill"></i> Home
          </NavLink>
          <ul className="dropdown-menu bg-light ">
            <li>
              <NavLink className="dropdown-item" to="/Home">
                Carousel
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/Our_producthome">
                Our Products
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/Our_Gallaryhome">
                Our Gallery
              </NavLink>
            </li>
          </ul>
        </NavLink>
        


        <NavLink  className="nav-item dropdown">
          <NavLink
            className="nav-link dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-file-person-fill"></i> About Us
          </NavLink>
          <ul className="dropdown-menu bg-light ">
            <li>
              <NavLink className="dropdown-item" to="/About Background Image">
                Background Image
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/Team_members">
                Team
              </NavLink>
            </li>
           
          </ul>
        </NavLink>
        
        <NavLink  className="nav-item dropdown">
          <NavLink
            className="nav-link dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-basket2-fill"></i>Cereals
          </NavLink>
          <ul className="dropdown-menu bg-light ">
            <li>
              <NavLink className="dropdown-item" to="/Cereals">
                Background Image
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/CerealsProductImage">
                 products image 
              </NavLink>
            </li>
           
          </ul>
        </NavLink>
        
        
       
        <NavLink  className="nav-item dropdown">
          <NavLink
            className="nav-link dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-apple"></i> Fruit and vegetable
          </NavLink>
          <ul className="dropdown-menu bg-light ">
            <li>
              <NavLink className="dropdown-item" to="/Fruitandvegbg">
                Background Image
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/FruitandveProduct">
                 products image 
              </NavLink>
            </li>
           
          </ul>
        </NavLink>
       
        <NavLink  className="nav-item dropdown">
          <NavLink
            className="nav-link dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
           <i class="bi bi-egg-fill"></i> processed Food
          </NavLink>
          <ul className="dropdown-menu bg-light ">
            <li>
              <NavLink className="dropdown-item" to="/ProcessedfoodBg">
                Background Image
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/ProcessedfoodProduct">
                 products image 
              </NavLink>
            </li>
           
          </ul>
        </NavLink>
        
        <NavLink  className="nav-item dropdown">
          <NavLink
            className="nav-link dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
           <i class="bi bi-fire"></i>spices
          </NavLink>
          <ul className="dropdown-menu bg-light ">
            <li>
              <NavLink className="dropdown-item" to="/SpiceBg">
                Background Image
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/SpiceProduct">
                 products image 
              </NavLink>
            </li>
           
          </ul>
        </NavLink>
       
        <NavLink  className="nav-item dropdown">
          <NavLink
            className="nav-link dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
          <i class="bi bi-camera-fill"></i> Gallery
          </NavLink>
          <ul className="dropdown-menu bg-light ">
            <li>
              <NavLink className="dropdown-item" to="/GallaryBg">
                Background Image
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/GallaryProduct">
                 products image 
              </NavLink>
            </li>
           
          </ul>
        </NavLink>

        <NavLink  className="nav-item dropdown">
          <NavLink
            className="nav-link dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
         <i class="bi bi-chat-dots-fill"></i>Inquiry
          </NavLink>
          <ul className="dropdown-menu bg-light ">
            <li>
              <NavLink className="dropdown-item" to="/inquryBackground">
                Background Image
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/inqury">
               inquiry Data 
              </NavLink>
            </li>
           
          </ul>
        </NavLink>
        
        <Link to="/Win_international_Trade">
          <i class="bi bi-star-fill"></i> Win International Trade
        </Link>
        <Link to="/Get_in_touch" className="mb-2">
          <i class="bi bi-chat-left-fill"></i> Get In Touch
        </Link>
        <div onClick={clearstorage} className="text-center">
          <i class="bi bi-person-circle me-auto btn btn-danger border-0">
            {" "}
            Sign Out
          </i>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
