import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function WininternationalbgEdit(props) {
  console.log(props.value);

  const history = useNavigate();

  const [file17, setFile17] = useState(null);
  const [inputVal17, setzinputVal17] = useState({
    Wininternationalbgimgpath:"",
    fname17: "",
    date: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setzinputVal17((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData = async () => {
    const res4 = await fetch(`https://pratik-agro-backend.vercel.app/getWininternationalbgimgpath/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data3 = await res4.json();

    if (res4.status === 422 || !data3) {
      console.log("error");
    } else {
      console.log(data3);
    setzinputVal17({
        Wininternationalbgimgpath: data3.Wininternationalbgimgpath,
      fname17: data3.fname17,
      date: data3.date,
    });
  }
  };
  useEffect(() => {
    getData();
  }, []);

  const updatData3 = async (e) => {
    e.preventDefault();

    console.log(inputVal17);

    var formData17 = new FormData();
    if (file17 != null) {
      formData17.append("image17", file17);
    }
    formData17.append("fname17", inputVal17.fname17);
    formData17.append("date", inputVal17.date);

    for (var pair of formData17.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updateWininternationalbgimgpath/${id}`, formData17, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/Win_international_Trade");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <>
      <div className="content ">
        <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/Win_international_Trade">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Background Image:</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image17"
              onChange={(e) => setFile17(e.target.files[0])}
              value={inputVal17.image17}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">BackgroundImage Name:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname17"
              required
              onChange={setData}
              value={inputVal17.fname17}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="date"
              required
              onChange={setData}
              value={inputVal17.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updatData3}>
              update Detail
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
export default WininternationalbgEdit;