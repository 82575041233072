import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function FruitandVegEdit() {
    const history = useNavigate();

  const [file8, setFile8] = useState(null);
  const [inputVal8, setinputVal8] = useState({
    FruitAndVegBgimg:"",
    fname8: "",
    date: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setinputVal8((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData = async () => {
    const res8 = await fetch(`https://pratik-agro-backend.vercel.app/getFruitAndVegBgImg/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data3 = await res8.json();

    if (res8.status === 422 || !data3) {
      console.log("error");
    } else {
      console.log(data3);
    setinputVal8({
        FruitAndVegBgimg: data3.FruitAndVegBgimg,
      fname8: data3.fname8,
      date: data3.date,
    });
  }
  };
  useEffect(() => {
    getData();
  }, []);

  const updateData8 = async (e) => {
    e.preventDefault();

    console.log(inputVal8);

    var formData8 = new FormData();
    if (file8 != null) {
      formData8.append("image8", file8);
    }
    formData8.append("fname8", inputVal8.fname8);
    formData8.append("date", inputVal8.date);

    for (var pair of formData8.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updateFruitAndVegbgimg/${id}`, formData8, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/Fruitandvegbg");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <div className='content'>
         <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/Fruitandvegbg">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Background Image:</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image8"
              onChange={(e) => setFile8(e.target.files[0])}
              value={inputVal8.image8}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Background Image Name: :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname8"
              onChange={setData}
              value={inputVal8.fname8}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="date"
              onChange={setData}
              value={inputVal8.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updateData8}>
              update Detail
            </Link>
          </div>
        </form>
    </div>
  )
}
