import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function ProcessedFoodProductEdit() {
    const history = useNavigate();

  const [file11, setFile11] = useState(null);
  const [inputVal11, setinputVal11] = useState({
     ProcessedFoodProductimg:"",
     ProcessedProductDescriptio:"",
    fname11: "",
    date: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setinputVal11((Data) => {
      return {
        ...Data,
        [name]: value,
      };
    });
  };
  const { id } = useParams("");
  console.log(id);
  const getData = async () => {
    const res11 = await fetch(`https://pratik-agro-backend.vercel.app/getProcessedFoodProductimg/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data3 = await res11.json();

    if (res11.status === 422 || !data3) {
      console.log("error");
    } else {
      console.log(data3);
    setinputVal11({
         ProcessedFoodProductimg: data3.ProcessedFoodProductimg,
         ProcessedProductDescriptio:data3.ProcessedProductDescriptio,
      fname11: data3.fname11,
      date: data3.date,
    });
  }
  };
  useEffect(() => {
    getData();
  }, []);

  const updateData8 = async (e) => {
    e.preventDefault();

    console.log(inputVal11);

    var formData11 = new FormData();
    if (file11 != null) {
      formData11.append("image11", file11);
    }
    formData11.append("fname11", inputVal11.fname11);
    formData11.append("date", inputVal11.date);
    formData11.append("ProcessedProductDescriptio",inputVal11.ProcessedProductDescriptio);

    for (var pair of formData11.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(`https://pratik-agro-backend.vercel.app/updateProcessedFoodProductimg/${id}`, formData11, config)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          history("/ProcessedfoodProduct");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  return (
    <div className='content'>
         <div className="container mt-5 ">
          <Link className="btn btn-dark" to="/ProcessedfoodProduct">
          <i class="bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>

        <form className="mx-auto mt-5 shadow p-5">
          <div className="container ">
            <h6>Fill This Form:-</h6>
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Product Image :</label>
            <input
              type="file"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="image11"
              onChange={(e) => setFile11(e.target.files[0])}
              value={inputVal11.image11}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Product Name: :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="fname11"
              onChange={setData}
              value={inputVal11.fname11}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Description :</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="ProcessedProductDescriptio"
              onChange={setData}
              value={inputVal11.ProcessedProductDescriptio}
            />
          </div>
          <div className="mb-3 w-50 ">
            <label className="form-label">Date:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="date"
              onChange={setData}
              value={inputVal11.date}
            />
          </div>
          <div>
            <Link className="btn btn-success  mt-3" onClick={updateData8}>
              update Detail
            </Link>
          </div>
        </form>
    </div>
  )
}
