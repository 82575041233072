import axios from "axios";
import {  useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import BellNotifiaction from "./BellNotifiaction";
import Modal from "react-modal";

Modal.setAppElement("#root");

export default function Home() {
  const [fname, setfname] = useState("");
  const [newFile, setNewFile] = useState(null);
  const [newdata, setnewdata] = useState([]);
  const [frontendpath, setnewfrontendpath] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


 

  const setData = (e) => {
    const { value } = e.target;
    setfname(value);
  };
  const setData9 = (e) => {
    const { value } = e.target;
    setnewfrontendpath(value);
  };
  const setimgfile = (e) => {
    setNewFile(e.target.files[0]);
  };

  // Add image

  const adduserdata = async (e) => {
    
    var formdata = new FormData();
    console.log(newFile);
    var filename = newFile.name;
    formdata.append("photo", newFile, filename);
    formdata.append("fname", fname);
    formdata.append("frontendpath",frontendpath);
    console.log(formdata);

    const config = {
      headers: {
        "Content-Type": " multipart/form-data",
      },
    };

    const res = await axios.post(
      "https://pratik-agro-backend.vercel.app/AddHomeImg",
      formdata,
      config
    );

    console.log(res);
  };
  //get image

  const getUserData = async () => {
    const res2 = await axios.get("https://pratik-agro-backend.vercel.app/getImg", {
      headers: {
        "Content-Type": " application/json",
      },
    });
    if (res2.data.status === 401 || !res2.data) {
      console.log("error");
    } else {
      setnewdata(res2.data.getImage.reverse());
    }
  };
  useEffect(() => {
    getUserData();
  }, []);
 
//delete data
const deleteimage = async (id) => {
 
  const res3 = await fetch(`https://pratik-agro-backend.vercel.app/deletedata/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const deleteStud = await res3.json();
  console.log(deleteStud);
  if (res3.status === 422 || !deleteStud) {
    console.log("error");
  } else {
    getUserData();
  }

};

  return (
    <div className="content">
      
      
      
      
      <div className="mt-2 mb-1 d-flex justify-content-between align-items-center">
        <i>
          <h2>Carousel</h2>
        </i>
       <div>
     
       <BellNotifiaction />
       <button
          type="button"
          to="/Addhomeimage"
          className="btn btn-light shadow addimage "
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
         
        >
          Add Image
        </button>
       </div>
        
        
      </div>
      

      <div className=" container text-end mt-5">
        {/* <Link  to="/Addhomeimage"className="btn btn-success "> Add Data</Link> */}
        
        <div
          class="modal fade "
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                  Home Image
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form>
                  <div>
                    
                      <p style={{float:'left'}}>Carousel image Name:-</p>
                    
                    <input
                      type="text"
                      name="fname"
                      className="form-control"
                      id="exampleInputEmail1"
                      required
                      aria-describedby="emailHelp"
                      onChange={setData}
                    />
                     <p style={{float:'left'}}>image path:-</p>
                    <input
                      type="text"
                      name="cerealDescription"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setData9}
                    />

                    <div>
                      <p style={{float:'left'}}>Add Your Image:-</p>
                    </div>
                    <input
                      type="file"
                      name="photo"
                      required
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={setimgfile}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary mt-5"
                      onClick={adduserdata}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="outer-wrapper">
    <div class="table-wrapper">
      <table class="table  mt-5">
        <thead className="bg-dark text-white ">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Carousel Image</th>
            <th scope="col">Name</th>
            <th scope="col">image Path</th>
            <th scope="col">Uploaded Date</th>
            <th scope="col">Action</th>
            
          </tr>
        </thead>
        <tbody>
          {newdata.length>0 ?newdata.map((el,id) => {
            console.log(el)
            return (
              <>
                <tr>
                  <th scope="row mt-5">{id+1}</th>
                  <td>
                      <img
                        src={el.imgpath}
                        alt="img"
                        style={{
                          width: "70px",
                          textAlign: "center",
                          margin: "auto",
                          cursor:"pointer"
                        }}
                        onClick={() => handleImageClick(el.imgpath)}
                      />
                    </td>
                  <td>{el.fname}</td>
                  <td>{el.frontendpath}</td>
                  <td>{el.date}</td>
                  {/* <td>
                    <NavLink to={`/Edit/${el._id}`}><i className="bi bi-pencil-square p-4 shadow"  ></i></NavLink>
                    <i class="bi bi-archive-fill p-4 shadow" onClick={()=>deleteimage(el._id)}></i>
                  </td> */}
                  <td>
                        <button type="button" class="btn btn-outline-dark "><NavLink to={`/Edit/${el._id}`}><i className="bi bi-pencil-square "  ></i></NavLink></button>
                        <button type="button" class="btn btn-outline-dark "><i class="bi bi-archive-fill " onClick={()=>deleteimage(el._id)}></i></button>
                  </td>
                </tr>
              </>
            );
          }):"Loading..."
          } 
        </tbody>
        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
  <img src={selectedImage} alt="img" style={{width: '100%', height: 'auto'}} className='img-fluid' />
</Modal>
      </table>
      </div>
</div>
      
    </div>
    
  );
}
