import React, { useEffect, useState } from "react";

export default function BellNotifiaction() {
  const [showModal, setShowModal] = useState(false);
  const [getdata, setgetData] = useState([]);
  const handleModal = () => {
    setShowModal(!showModal);
  };
  const getData = async () => {
    const res = await fetch("https://pratik-agro-backend.vercel.app/getGetinTouchmsgDetails", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    console.log(data);
    if (res.status === 422 || !data) {
      console.log(data);
    } else {
      setgetData(data.getDetails.reverse());
      console.log("get data");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  //delete data
  const deleteimage = async (id) => {
    const res3 = await fetch(`https://pratik-agro-backend.vercel.app/deletedata19/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const deleteStud = await res3.json();
    console.log(deleteStud);
    if (res3.status === 422 || !deleteStud) {
      console.log("error");
    } else {
      getData();
    }
  };
  return (
    <div className="bell-icon" onClick={handleModal}>
      <i className="bi bi-envelope-fill"></i>

      {showModal && (
        <div className="popup-modal">
          <h3>Inqury</h3>
          <ul>
            {getdata.map((el) => {
              return (
                <>
                  <li>
                    <div className="d-flex">
                      <div>
                        <h6 class="item-title">{el.Subject}</h6>
                        <p class="item-info">
                          Name:{el.Name},<br></br>
                          Email:{el.Email},<br></br>
                          Contact:{el.Contact_Number}
                          
                        </p>
                        <p class="item-info">Message:<br></br>
                        {el.Message}</p>
                      </div>
                      <div className="text-center deletericonmdel">
                        <i class="bi bi-archive-fill" onClick={()=>deleteimage(el._id)}></i>
                      </div>
                    </div>
                  </li>
                  <hr></hr>
                </>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
