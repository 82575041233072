import { useState } from "react";
import { NavLink } from "react-router-dom";
import Pagination from "./Pagination";
import Modal from "react-modal";

Modal.setAppElement("#root");
const PostsOfGalleryProducts = ({ posts,deleteimage }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [postPerPage] = useState(9);
  console.log(posts);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  
  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  if (posts.length <= 0) {
    return <h2>Loading...</h2>;
  }

  const currentPost = posts.slice(
    postPerPage * currentPage,
    postPerPage * (currentPage + 1)
  );
  return (
    <>  <div class="outer-wrapper">
    <div class="table-wrapper"> 
    <table class="table  mt-5">
    <thead className="bg-dark text-white ">
    <tr>
      <th scope="col">ID</th>
      <th scope="col"> Product Image</th>
      <th scope="col"> Product Name</th>
      <th scope="col">Uploaded Date</th>
      <th scope="col">Action</th>
      
    </tr>
  </thead>
     {
        currentPost.map((post,id)=>{
            console.log(post);
            return(
              <tbody> <tr>
          <th scope="row ">{id+1}</th>
          <td>
            <img
              src={post.GallaryProductimg}
              alt="img"
              style={{ width: "70px", textAlign: "center", margin: "auto",cursor:"pointer" }}
              onClick={() => handleImageClick(post.GallaryProductimg)}
            />
          </td>
          <td>{post.fname15}</td>
          <td>{post.date}</td>

          <td>
            <button type="button" class="btn btn-outline-dark ">
              <NavLink to={`/GallaryProductEdit/${post._id}`}>
                <i className="bi bi-pencil-square "></i>
              </NavLink>
            </button>
            <button type="button" class="btn btn-outline-dark ">
              <i
                class="bi bi-archive-fill "
                  onClick={()=>deleteimage(post._id)}
              ></i>
            </button>
          </td>
        </tr></tbody>
              
             )
            
        })
     }
     <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} style={{ content: { width: '50%', height: '50%', margin: 'auto', backgroundColor: 'white' } }}>
     <button onClick={handleCloseModal} className="btn btn-danger"><i class="bi bi-x-lg"></i></button>
  <div className='text-center mt-5'>
    <img src={selectedImage} alt="img" style={{width: '30%', height: '35%%'}} className='text-center' />
  </div>
  
</Modal>
     </table>
     </div>
     </div>
      <Pagination
        postPerPage={postPerPage}
        totalPost={posts.length}
        paginate={(e) => setCurrentPage(e)}
    
      />
     
        
      
      
    </>
   
   
  );
};
export default PostsOfGalleryProducts;
