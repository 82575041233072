import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import BellNotifiaction from '../BellNotifiaction'
import Modal from "react-modal";

Modal.setAppElement("#root");
export default function GallaryBg() {
    const [fname14, setfname14] = useState("");
    const [newFile14, setNewFile14] = useState(null);
    const [newdata14, setNewData14] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    
    const handleImageClick = (imageSrc) => {
      setSelectedImage(imageSrc);
      setIsModalOpen(true);
    };
    
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
  
    const setData14 = (e) => {
      const { value } = e.target;
      setfname14(value);
    };
    const setimgfile = (e) => {
      setNewFile14(e.target.files[0]);
    };
  
    // Add image
  
    const adduserData14 = async (e) => {
      
      var formData14 = new FormData();
      console.log(newFile14);
      var filename = newFile14.name;
      formData14.append("photo14", newFile14, filename);
      formData14.append("fname14", fname14);
      console.log(formData14);
  
      const config = {
        headers: {
          "Content-Type": " multipart/form-data",
        },
      };
  
      const res = await axios.post(
        "https://pratik-agro-backend.vercel.app/AddGallaryBgimg",
        formData14,
        config
      );
  
      console.log(res);
    };
    //get image
  
    const getUserData14 = async () => {
      const res14 = await axios.get("https://pratik-agro-backend.vercel.app/getGallaryBgimg", {
        headers: {
          "Content-Type": " application/json",
        },
      });
      if (res14.data.status === 401 || !res14.data) {
        console.log("error");
      } else {
        setNewData14(res14.data.getImage.reverse());
      }
    };
    useEffect(() => {
      getUserData14();
    }, []);
   
  //delete data
  const deleteimage = async (id) => {
   
    const res6 = await fetch(`https://pratik-agro-backend.vercel.app/deletedata14/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const deleteStud = await res6.json();
    console.log(deleteStud);
    if (res6.status === 422 || !deleteStud) {
      console.log("error");
    } else {
      getUserData14();
    }
  
  };
  return (
    <div className='content'>
    <div className="mt-2 mb-1 d-flex justify-content-between align-items-center">
        <i>
          <h2>Background</h2>
        </i>
       <div>
     
       <BellNotifiaction />
       <Link
          type="button"
          to="/Addhomeimage"
          className="btn btn-light shadow addimage "
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Add Image
        </Link>
       </div>
        
        
      </div>
      <div className=" container text-end mt-5">
        {/* <Link  to="/Addhomeimage"className="btn btn-success "> Add Data</Link> */}
        
        <div
          class="modal fade "
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                  Gallary BackgroundImage
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form>
                  <div>
                    
                      <p style={{float:'left'}}>Background Image Name:-</p>
                    
                    <input
                      type="text"
                      name="fname14"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setData14}
                    />

                    <div>
                      <p style={{float:'left'}}>Add Your Image:-</p>
                    </div>
                    <input
                      type="file"
                      name="photo14"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                       onChange={setimgfile}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary mt-5"
                       onClick={adduserData14}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="outer-wrapper">
    <div class="table-wrapper"> 
        <table class="table  mt-5">
        
        <thead className="bg-dark text-white">
          <tr>
            <th scope="col">ID</th>
            <th scope="col"> Background  Image</th>
            <th scope="col"> Image Name</th>
            <th scope="col">Uploaded Date</th>
            <th scope="col">Action</th>
            
          </tr>
        </thead>
        <tbody>
          {newdata14.length>0?newdata14.map((el,id) => {
            console.log(el)
            return (
              <>
                <tr>
                  <th scope="row mt-5">{id+1}</th>
                  <td><img src={el.GallaryBgimg} alt='img'  style={{'width':'70px',textAlign:"center",margin:'auto',cursor:"pointer"}}onClick={() => handleImageClick(el.GallaryBgimg)}/></td>
                  <td>{el.fname14}</td>
                  <td>{el.date}</td>
                  {/* <td>
                    <NavLink to={`/Edit/${el._id}`}><i className="bi bi-pencil-square p-4 shadow"  ></i></NavLink>
                    <i class="bi bi-archive-fill p-4 shadow" onClick={()=>deleteimage(el._id)}></i>
                  </td> */}
                  <td>
                        <button type="button" class="btn btn-outline-dark "><NavLink to={`/GallaryBgEdit/${el._id}`}><i className="bi bi-pencil-square "  ></i></NavLink></button>
                        <button type="button" class="btn btn-outline-dark "><i class="bi bi-archive-fill " onClick={()=>deleteimage(el._id)}></i></button>
                  </td>
                </tr>
              </>
            );
          }):"Loading..."
          } 
        </tbody>
        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
  <img src={selectedImage} alt="img" style={{width: '100%', height: 'auto'}} className='img-fluid' />
</Modal>
      </table>
      </div>
      </div>
    </div>
  )
}
