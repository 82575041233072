import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import BellNotifiaction from "../BellNotifiaction";
import Modal from "react-modal";

Modal.setAppElement("#root");

export default function Cereals() {
  const [fname6, setfname6] = useState("");
  const [newFile6, setNewFile6] = useState(null);
  const [newdata6, setnewdata6] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };






  const setData6 = (e) => {
    const { value } = e.target;
    setfname6(value);
  };
  const setimgfile = (e) => {
    setNewFile6(e.target.files[0]);
  };

  // Add image

  const adduserData6 = async (e) => {
    var formdata6 = new FormData();
    console.log(newFile6);
    var filename = newFile6.name;
    formdata6.append("photo6", newFile6, filename);
    formdata6.append("fname6", fname6);
    console.log(formdata6);

    const config = {
      headers: {
        "Content-Type": " multipart/form-data",
      },
    };

    const res = await axios.post(
      "https://pratik-agro-backend.vercel.app/AddCerealsbgimg",
      formdata6,
      config
    );

    console.log(res);
  };
  //get image

  const getUserData6 = async () => {
    const res2 = await axios.get("https://pratik-agro-backend.vercel.app/getCerealsbgImg", {
      headers: {
        "Content-Type": " application/json",
      },
    });
    if (res2.data.status === 401 || !res2.data) {
      console.log("error");
    } else {
      setnewdata6(res2.data.getImage.reverse());
    }
  };
  useEffect(() => {
    getUserData6();
  }, []);

  //delete data
  const deleteimage = async (id) => {
    const res6 = await fetch(`https://pratik-agro-backend.vercel.app/deletedata6/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const deleteStud = await res6.json();
    console.log(deleteStud);
    if (res6.status === 422 || !deleteStud) {
      console.log("error");
    } else {
      getUserData6();
    }
  };
  return (
    <div className="content">
      <div className="mt-2 mb-1 d-flex justify-content-between align-items-center">
        <i>
          <h2>Background </h2>
        </i>
       <div>
     
       <BellNotifiaction />
       <Link
          type="button"
          to="/Addhomeimage"
          className="btn btn-light shadow addimage "
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Add Image
        </Link>
       </div>
        
        
      </div>
      <div className=" container text-end mt-5">
        {/* <Link  to="/Addhomeimage"className="btn btn-success "> Add Data</Link> */}

        <div
          class="modal fade "
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                   Cereals Background
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form>
                  <div>
                    <p style={{ float: "left" }}>Background Image Name:-</p>

                    <input
                      type="text"
                      name="fname6"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={setData6}
                    />

                    <div>
                      <p style={{ float: "left" }}>Add Your Image:-</p>
                    </div>
                    <input
                      type="file"
                      name="photo6"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={setimgfile}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary mt-5"
                      onClick={adduserData6}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="outer-wrapper">
    <div class="table-wrapper">
      <table class="table  mt-5">
        <thead className="bg-dark text-white">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Background Image</th>
            <th scope="col">Background Image Name</th>
            <th scope="col">Uploaded Date</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
        {newdata6.length > 0
          ? newdata6.map((el, id) => {
              return (
                <>
                  <tr>
                    <th scope="row mt-5">{id + 1}</th>
                    <td>
                      <img
                        src={el.cerealsBackgroundimg}
                        alt="img"
                        style={{
                          width: "70px",
                          textAlign: "center",
                          margin: "auto",
                          cursor:"pointer"
                        }}
                        onClick={() => handleImageClick(el.cerealsBackgroundimg)}
                      />
                    </td>
                    <td>{el.fname6}</td>
                    <td>{el.date}</td>
                    <td>
                      <button type="button" class="btn btn-outline-dark ">
                        <NavLink to={`/CerealsbgEdit/${el._id}`}>
                          <i className="bi bi-pencil-square "></i>
                        </NavLink>
                      </button>
                      <button type="button" class="btn btn-outline-dark ">
                        <i
                          class="bi bi-archive-fill "
                          onClick={() => deleteimage(el._id)}
                        ></i>
                      </button>
                    </td>
                  </tr>
                </>
              );
            })
          : "Loading.."}
      </tbody>
      <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
  <img src={selectedImage} alt="img" style={{width: '100%', height: 'auto'}} className='img-fluid' />
</Modal>
      </table>
      </div>
      </div>
    </div>
  );
}
